
.tab-link-base {
  @apply w-full p-2 text-gray-700 flex items-center justify-center cursor-pointer;
}
.tab-link-active {
  @apply bg-white;
}
.tab-link-inactive {
  @apply bg-gray-400;
}
.tab-link-inactive:hover {
  @apply bg-gray-300;
}

.tab-svg-base {
  @apply text-primary-main fill-current inline-block mr-2;
}
.tab-svg-inactive {
  @apply text-gray-700;
}
