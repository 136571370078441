@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-light: hsla(210, 70%, 80%, 1);
  --primary-main: hsla(210, 70%, 70%, 1);
  --primary-dark: hsla(210, 55%, 65%, 1);

  --secondary-light: hsla(360, 55%, 75%, 1);
  --secondary-main: hsla(360, 55%, 65%, 1);
  --secondary-dark: hsla(360, 40%, 60%, 1);

  --background-main: hsla(210, 70%, 70%, 1);
}

.multiple-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.input-error {
  @apply border-red-500;
}

.input-disabled {
  @apply bg-gray-400 border-gray-500;
}

.error-message {
  @apply text-red-500 text-xs text-left py-1;
}

.text-2xs {
  font-size: 0.65rem;
}
