
.main {
  @apply bg-primary-main text-white;
}

.outlined {
  @apply border border-primary-main text-primary-main;
}

.disabled {
  @apply bg-gray-400 cursor-not-allowed;
}
