
.success {
  @apply bg-green-500;
}

.error {
  @apply bg-red-600;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
