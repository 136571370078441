
.line {
  @apply bg-line-main text-white;

  .line-vertical {
    @apply border-lineVertical-main;
  }
}

.line:hover {
  @apply bg-line-hover;

  &:hover .line-vertical {
    @apply border-lineVertical-hover;
  }
}

.line:active {
  @apply bg-line-active;

  &:active .line-vertical {
    @apply border-lineVertical-active;
  }
}
